import React from 'react';
import './VosslohHeader.css';

export const VosslohHeader = React.memo(function VosslohHeader() {
  return (
    <div className="vossloh-header" style={{ paddingLeft: '24px', marginTop: '12px' }}>
      <a href="/" style={{ textDecoration: 'none' }}>
        <img src="public/img/vossloh_logo.svg" alt="Vossloh" className="vossloh-logo" style={{ height: '50px' }} />
      </a>
      <span style={{ marginLeft: '24px' }}>Smart Point Machine</span>
      <div className="vossloh-links">
        <a href="/" style={{ color: '#33ae33' }}>
          View
        </a>
        <a style={{ textDecoration: 'none' }} onClick={() => (window.location.href = '/setup')}>
          Setup
        </a>
        {/* <!--<a href="/admin">Admin</a>--> */}
      </div>
    </div>
  );
});
